import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types'; // Import PropTypes

const MapLocator = () => {
  const [location, setLocation] = useState(null);

  const handleMapClick = (event) => {
    // Get the selected location and update state
    const selectedLocation = {
      lat: event.lat,
      lng: event.lng,
    };
    setLocation(selectedLocation);

    // Update your input fields with selectedLocation here
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCbknAy3ZfqL2hw9HKAc16jVDkWydyMAwE' }}
        defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
        defaultZoom={8}
        onClick={handleMapClick}
      >
        {location && (
          <Marker
            lat={location.lat}
            lng={location.lng}
            text="Selected Location"
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

// A simple Marker component
const Marker = ({ text }) => <div className="marker">{text}</div>;

Marker.propTypes = {
    text: PropTypes.string.isRequired, // Modify the type as needed
  };

export default MapLocator;
