

import { useState, useEffect, useMemo } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
import routes from "routes";
import usRoutes from "usRoutes";
// Images
import brand from "assets/images/courtena-logo-black-nobg.png";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import courtena from "api/courtena";
import SoftAlert from "components/SoftAlert";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  let navigate = useNavigate()
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [dashboardData,setDashboardData] = useState()
    const [isSubscribed,setIsSubscribed] = useState(true)
    const [isFirstTimeSubscribe,setIsFirstTimeSubscribe] = useState(false)

  const getDashboardData = async () => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    await courtena.get("/partner/get-partner-dashboard-data/"+partnerInfo._id,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
    }}).then((res) => {
      setDashboardData(res.data.result)
    }).catch(err => {
      console.log(err)
    })
  }


  const checkUser = async() => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    await courtena.get("/partner/get-partner-info/"+partnerInfo._id,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
    }}).then((res) => {
      if(res.data.success){
        setIsSubscribed(res.data.result.partner.isSubscribed)
        const myObject = JSON.parse(localStorage.getItem('partner'));
          myObject.isSubscribed = res.data.result.partner.isSubscribed;
          localStorage.setItem('partner', JSON.stringify(myObject));
        if(res.data.result.partnerSubscription){
          setIsFirstTimeSubscribe(false)
        }else{
          setIsFirstTimeSubscribe(true)
        }
        
      }
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    const loggedIn = Boolean(localStorage.getItem('partnerRemainLoggedIn'));
    if(loggedIn){
      if(loggedIn == true){
        checkUser()
      }
    }
    getDashboardData()
  },[])

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );
  return (
    <DashboardLayout>
      <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Courtena"
              routes={isSubscribed?routes:usRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
      <DashboardNavbar light={true} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {isSubscribed === false && isFirstTimeSubscribe === false ?<Grid item xs={12} sm={12} xl={12}>
              <SoftAlert color='error'>Please pay your subscription to access all features <SoftButton onClick={async () => {
                var partnerInfoString = localStorage.getItem("partner")
                var partnerInfo = JSON.parse(partnerInfoString)
                const data = { amount: 170000, description: "Subscription for courtena partner platform by"+partnerInfo.first_name+" "+partnerInfo.last_name, success_url: "https://partner.courtena.com/payment/success", callback_url: "https://partner.courtena.com/payment/success", currency: "SAR" }
                await courtena.post("/partner/subscription/generate-moyasar-invoice-for-partner", { ...data }, {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    'Authorization': partnerInfo.token
                  }
                }).then((res) => {
                  window.open(res.data.result.url, '_self');
                  // const myObject = JSON.parse(localStorage.getItem('partner'));
                  // myObject.isSubscribed = res.data.result.partner.isSubscribed;
                  // localStorage.setItem('partner', JSON.stringify(myObject));
                }).catch(err => {
                  console.log(err)
                })
              }} style={{marginLeft:20}}>Pay</SoftButton></SoftAlert>

            </Grid>:null}

            {isSubscribed === false && isFirstTimeSubscribe === true ?<Grid item xs={12} sm={12} xl={12}>
              <SoftAlert color='error'>Please Subscribe to access all features <SoftButton onClick={async () => {
                navigate("/subscribe")
              }} style={{marginLeft:20}}>Subscribe</SoftButton></SoftAlert>

            </Grid>:null}
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Revenue" }}
                count={dashboardData?.settlement?`SAR ${(dashboardData?.settlement.amountSettled + dashboardData?.settlement.amountToSettle)/100}`:"SAR 0"}
                // percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "secondary", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Customers" }}
                count={dashboardData?.customerCount}
                // percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "secondary", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Cleared Settlements" }}
                count={dashboardData?.settlement?`SAR ${dashboardData?.settlement.amountSettled/100}`:"SAR 0"}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "secondary", component: "card_membership" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Bookings" }}
                count={dashboardData?.bookingCount}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "book_online",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Upcoming Settlement" }}
                count={dashboardData?.settlement?`SAR ${dashboardData?.settlement.amountToSettle/100}`:"SAR 0"}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "card_membership",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Player Age Range" }}
                count={dashboardData?.majorityAgeRange+" years old"}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "date_range",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Player genders range" }}
                count={dashboardData?.malePercentage +"% M " + dashboardData?.femalePercentage + "% F" }
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "wc",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Peak time" }}
                count={dashboardData?.peakTimeRangeWithMostBookings}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "access_time",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Peak Day" }}
                count={dashboardData?.dayOfWeekWithMostBookings}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "calendar_month",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Busiest Court" }}
                count={dashboardData?.busiestCourt}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "secondary",
                  component: "sports_tennis",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        {/* <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox> */}
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    (<strong>+23%</strong>) than last week
                  </>
                }
                color="dark"
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="dark" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="dark" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid> */}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
