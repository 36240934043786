/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import partnersTableData from "layouts/partners/data/partnersTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Backdrop, Chip, CircularProgress, Grid, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
// import partnersTableData from "./data/partnersTableData";
import { useNavigate } from "react-router-dom";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { useEffect, useState } from "react";
import courtena from "api/courtena";

function Settlements() {
  // const { columns, rows } = partnersTableData;
  // const { columns: prCols, rows: prRows } = projectsTableData;
  const [settlementData, setSettlementData] = useState()
  const [commission, setCommission] = useState()
  const [unsettledBookings, setUnsettledBookings] = useState()
  const [settledBookings, setSettledBookings] = useState()
  const [totalRevenue, setTotalRevenue] = useState()
  const [settledRevenue, setSettleRevenue] = useState()
  const [unsettledRevenue, setUnsettleRevenue] = useState()
  const [backdrop, setBackdrop] = useState(false)
  let navigate = useNavigate();

  const partnersTableData = {
    columns: [
      { name: "payment_id", align: "center" },
      { name: "booking_date", align: "center" },
      { name: "payment_amount", align: "center" },
      // { name: "venue", align: "center" }
    ],
  };
  const { columns } = partnersTableData;

  const getPartnerSettlements = async () => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    setBackdrop(true)
    await courtena.get("/partner/get-partner-settlements/" + partnerInfo._id, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
      }
    }).then((res) => {
      console.log(res.data)
      setBackdrop(false)
      if (res.data.success) {
        let bookingsToSettle = []
        res.data.result.settlement.bookingsToSettle.map((item) => {
          const commissionPercentage = res.data.result.commission.commission; // assuming commission is in percentage
          const commissionAmount = (commissionPercentage / 100) * item.paymentAmount;
          const paymentAmountAfterCommission = item.paymentAmount - commissionAmount;
          bookingsToSettle.push({
            payment_id: (<Chip label={item.payment.id} color="info" />),
            booking_date: (<Chip label={item.date} />),
            payment_amount: (<Chip label={paymentAmountAfterCommission + " SAR"} />),
          })
        })
                        // Calculate commission amount
                        let commissionAmount = 0;
                        let commissionPercentage = 0;
                        if (res.data.result.commission.commission !== null && res.data.result.commission.commission !== undefined && res.data.result.commission.commission !== '') {
                            commissionPercentage = res.data.result.commission.commission; // assuming commission is in percentage
                            commissionAmount = (commissionPercentage / 100) * (res.data.result.settlement.amountSettled + res.data.result.settlement.amountToSettle);
                        }
        
                        // Calculate totalRevenue after deducting commission
                        const totalRevenue = Math.max(((res.data.result.settlement.amountSettled + res.data.result.settlement.amountToSettle) - commissionAmount) / 100, 0);
        
                        // Calculate settledRevenue
                        const settledRevenue = Math.max((res.data.result.settlement.amountSettled - ((commissionPercentage / 100) * res.data.result.settlement.amountSettled)) / 100,0);
        
                        // Calculate unsettledRevenue
                        const unsettledRevenue =Math.max((res.data.result.settlement.amountToSettle - ((commissionPercentage / 100) * res.data.result.settlement.amountToSettle)) / 100,0);
        setUnsettledBookings(bookingsToSettle)
        setSettlementData(res.data.result.settlement)
        setCommission(res.data.result.commission.commission)
        setTotalRevenue(totalRevenue)
        setSettleRevenue(settledRevenue)
        setUnsettleRevenue(unsettledRevenue)
      } else {

      }
    }).catch(err => {
      setBackdrop(false)
      console.log(err)
    })
  }

  useEffect(() => {
    getPartnerSettlements()
  }, [])


  return (
    <DashboardLayout>
      <DashboardNavbar light={true} />
      <SoftBox py={3} >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} xl={3} mb={3}>
            <MiniStatisticsCard
              title={{ text: "Total Revenue" }}
              count={`SAR ${totalRevenue}`}
              // percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "secondary", component: "paid" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3} mb={3}>
            <MiniStatisticsCard
              title={{ text: "Settled Revenue" }}
              count={`SAR ${settledRevenue}`}
              // percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "secondary", component: "paid" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3} mb={3}>
            <MiniStatisticsCard
              title={{ text: "Unsettled Revenue" }}
              count={`SAR ${unsettledRevenue}`}
              // percentage={{ color: "success", text: "+55%" }}
              icon={{ color: "secondary", component: "paid" }}
            />
          </Grid></Grid>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Grid item xs={6} md={6}>
                <SoftTypography variant="h6">Unsettled Payments</SoftTypography>
              </Grid>
              {/* <Grid item xs={6} md={6}>
                <SoftButton onClick={() => navigate("/partners/add-partners")} variant="gradient" color="dark">
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Add Partner
                </SoftButton>
                </Grid> */}
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={unsettledBookings} />
            </SoftBox>
          </Card>
        </SoftBox>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settlements;
