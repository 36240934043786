/* eslint-disable react/prop-types */
/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import partnersTableData from "layouts/partners/data/partnersTableData";

import { Avatar, Backdrop, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon, MenuItem, Select, Tab, Tabs } from "@mui/material";
import SoftButton from "components/SoftButton";
// import partnersTableData from "./data/partnersTableData";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import courtena from "api/courtena";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import { ArrowDropDown, DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import SoftInput from "components/SoftInput";
function Subscribe() {
  const [pricing, setPricing] = useState([])
  const [backdrop, setBackdrop] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0);
  const [companyName, setCompanyName] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [taxIdType, setTaxIdType] = useState("Saudi Arabia TAX")
  const [taxNumber, setTaxNumber] = useState("")
  const [vatTax, setVatTax] = useState("")
  const [country, setCountry] = useState("Saudi Arabia")
  const [city, setCity] = useState("")
  const [streetAdrress, setStreetAddress] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [iban, setIban] = useState("")
  const [bicSwift, setBicSwift] = useState("")
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [tc, setTC] = useState(true)

  const termsAndConditions = [
    {
      term_ar: 'التطبيق:',
      term_en: 'The app:',
      description_ar: 'منصة رقمية مملوكة لكورتينا، تقدم خدمات تسهيل إجراءات حجز الملاعب الرياضية مقابل رسوم اشتراك.',
      description_en: 'A digital platform owned by Courtena provides services to facilitate sport court booking in return for a monthly subscription fee.'
    },
    {
      term_ar: 'باقة الاشتراك:',
      term_en: 'The subscription:',
      description_ar: 'خدمات محددة بمزايا مختلفة تقدمها كورتينا.',
      description_en: 'Specific services with various advantages provided by Courtena.'
    },
    {
      term_ar: 'الفترة المجانية:',
      term_en: 'Free period:',
      description_ar: 'هي خدمة إضافية تقدمها كورتينا، وتستمر لمدة أول شهرين فقط.',
      description_en: 'It is an extra service Courtena offers, and it is only available for the first two months.'
    },
    {
      term_ar: 'مبلغ الاشتراك:',
      term_en: 'Subscription fee:',
      description_ar: 'هو المبلغ الذي يقوم المشتركين شهريًا لكورتينا مقابل الخدمات المقدمة.',
      description_en: 'The amount that the subscriber will pay monthly to Courtena, in return for the provided services.'
    },
    {
      term_ar: 'النزاع:',
      term_en: 'Dispute:',
      description_ar: 'أي طلب استرداد أو خصم أو تعويض مقدم من العميل ضد المشتركين فيما يتعلق بالخدمات وجودتها، والتي تمت من خلال عملية حجز معتمدة عبر التطبيق.',
      description_en: 'Any request for a refund, deduction, or compensation submitted by the customer against the subscribers in respect of the services or its qualities obtained through an approved reservation process.'
    },
    {
      term_ar: 'الأنظمة المعمول بها:',
      term_en: 'Applicable Laws:',
      description_ar: 'جميع التشريعات والقوانين واللوائح والقواعد التنظيمية وشروط الترخيص المتعلقة بأي من الطرفين السارية بالمملكة العربية السعودية.',
      description_en: 'Refers to all laws, rules, and license requirements related to any party in the Kingdom of Saudi Arabia.'
    },
    {
      term_ar: 'قوانين حماية البيانات:',
      term_en: 'Data Protection Laws:',
      description_ar: 'جميع القوانين والإرشادات وقواعد الممارسات المعمول بها بشأن خصوصية البيانات في المملكة العربية السعودية.',
      description_en: 'Refers to all relevant laws, rules, and standards governing data privacy in the Kingdom of Saudi Arabia.'
    },
    {
      term_ar: 'يوم العمل:',
      term_en: 'Business Day:',
      description_ar: 'أيام العمل المعتمدة في المملكة العربية السعودية، والتي لا تشمل يومي الجمعة والسبت.',
      description_en: 'Refers to official working days in Saudi Arabia, which excludes Friday and Saturday.'
    },
    {
      term_ar: 'رسوم بوابة الدفع:',
      term_en: 'Payment Gateway Fees:',
      description_ar: 'رسوم الوسيلة التي تساعد على إتمام عمليات الحجز إلكترونيًا، باستخدام طرق الدفع وهي كالآتي: (4.9% عن كل عملية).',
      description_en: 'Refers to the fees for the method that helps complete online reservations, by using payment methods and they are as follows: (4.9% for each transaction).'
    },
    {
      term_ar: '1.1',
      term_en: '1.1',
      description_ar: 'تقدم كورتينا للمشتركين خدمة تسهيل إجراءات حجز العملاء من خلال تطبيق يمثل حلقة وصل بينهم، كما يمكّن التطبيق المشتركين من عرض خدماتهم والتسويق لأنشطتهم الرياضية لدى قاعدة العملاء المسجلين في التطبيق.',
      description_en: 'Courtena shall provide facilitate sport court booking through an application that works as a link between them, which the subscriber can market its sporting activities and provide its services to the customers registered on the app.'
    },
    {
      term_ar: '1.2',
      term_en: '1.2',
      description_ar: 'تقدم كورتينا خدمات تسهيل إجراءات الحجز للمشتركين وفقًا للشروط والأحكام المنصوص عليها في هذه الاتفاقية، وذلك مقابل اشتراك في الباقة المحددة في سياق هذه الاتفاقية.',
      description_en: 'Courtena shall provide facilitate sport court booking to the subscriber by the terms and conditions stipulated in this agreement, in return for the payment of subscriber for a specified subscription mentioned in this agreement.'
    },
    {
      term_ar: '1.3',
      term_en: '1.3',
      description_ar: 'تلتزم كورتينا بإدراج أيقونة المشتركين في التطبيق الخاص بها، بحيث تكون بارزة للعملاء لاختيارها، طوال فترة سريان هذا العقد.',
      description_en: 'Courtena shall insert the icon of the subscriber in his app in a way that makes it easily visible for customers to select, during the duration of this agreement\'s validity.'
    },
    {
      term_ar: '1.4',
      term_en: '1.4',
      description_ar: 'بقبول هذه الأحكام يقر المشترك أنه اطلع وعلم العلم النافي للجهالة بالأحكام والشروط وسياسة الخصوصية الواردة في الموقع الإلكتروني أو التطبيق الخاص بكورتينا.',
      description_en: 'By accepting these terms the subscriber  shall acknowledge that it has been thoroughly and duly informed of the terms and conditions and privacy policy contained in Courtena\'s website or application.'
    },
    {
      term_ar: '2- مدة الاتفاقية',
      term_en: '2- Agreement Duration ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '2.1',
      term_en: '2.1',
      description_ar: 'مدة الاتفاقية سنة ميلادية واحدة، وتجدد لمدة مماثلة مالم يخطر أحد الطرفين الطرف الآخر بعدم رغبته بالتجديد قبل انتهاء الاتفاقية بـ (30 يوم عمل).',
      description_en: 'The agreement duration is only for one year, and it shall be extended for a similar period unless one of the two parties notifies the other party of its unwillingness to renew it (30) days before the date of expiry of the agreement period. '
    },
    {
      term_ar: '3- الاشتراك والمدفوعات',
      term_en: '3- Subscription and Payments ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '3.1',
      term_en: '3.1',
      description_ar: 'يختار المشترك إحدى الباقات التي تقدمها كورتينا، والمذكورة في البند "٣.7" وبناءً عليها تكون القيمة المعتمدة للاشتراك الشهري بعد إتمام الفترة المجانية.',
      description_en: 'The subscriber selects the package he wants provided by Courtena and it mentioned in clause "3.7", accordingly the approved value for the monthly subscription will be after the completion of the free period.'
    },
    {
      term_ar: '3.2',
      term_en: '3.2',
      description_ar:'لا تستحق كورتينا أي مبلغ مالي خلال الشهرين الأولى من تاريخ توقيع العقد. (عدا رسوم بوابة الدفع فيتم احتسابها خلال الفترة المجانية).',
      description_en: 'For the first two months after the agreement signing, Courtena is not entitled to any money. (with the exception of payment gateway fees, which are computed throughout the free period.'
    },
    {
      term_ar: '3.3',
      term_en: '3.3',
      description_ar:'الفترة المجانية تكون مع بدء سريان العقد، إذ إنها لا تعتبر فترة تجريبية ولا تأخذ حكمها، أو تنتج آثارها.',
      description_en: 'The free period begins with the commencement of the agreement, and shall not be considered a trial period, nor shall have any legal implications or consequences.'
    },
    {
      term_ar: '3.4',
      term_en: '3.4',
      description_ar:'لن تكون كورتينا ملزمةَ بأداء خدماتها، حتى يتم استلام مبلغ الاشتراك كاملًا.',
      description_en: 'Courtena shall not be obliged to perform its services until the full subscription is received.'
    },
    {
      term_ar: '3.5',
      term_en: '3.5',
      description_ar:'تقوم كورتينا بتحويل المبالغ المستحقة للمشترك كل يوم ثلاثاء من كل أسبوع، وإذا رغب المشترك بتحويل المبالغ يوميُا فيتم فرض رسوم الحوالات البنكية عليه وهي (10 ريالات عن كل تحويل).',
      description_en: 'Courtena transfers the payments due on Tuesday of every week. If the subscriber wants a daily transfer (10 Riyal bank fee will be charged for each transfer).'
    },
    {
      term_ar: 'يتم سداد المبالغ المستحقة للمشترك على الحساب البنكي وتفاصيله كالآتي:',
      term_en: 'The payments due shall be paid to the subscriber bank account whose details as follows:',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: 'اسم البنك:',
      term_en: 'Bank Name:',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: 'الحساب:',
      term_en: 'Account: ',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: 'رقم الآيبان:',
      term_en: 'IBAN NO: ',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: '3.6',
      term_en: '3.6',
      description_ar:'سيتـم خصـم مبـلغ الاشتــراك الشهـري من المبـالغ المحولة إلـى المشترك، وفي حال عدم كفاية الرصيد لاستقطاع مبلغ الاشتراك منه، يقوم المشترك بتحويل مبلغ الاشتراك الشهري لكورتينا على الحساب البنكي وتفاصيله كالآتي:',
      description_en: 'The subscription shall be deducted from the amount transferred to the subscriber. If the amount does not have sufficient balance to cover the subscription, the subscriber shall transfer the subscription to Courtena bank account whose details as follows:'
    },
    {
      term_ar: 'اسم البنك: البنك الأهلي السعودي',
      term_en: 'Bank Name: SNB',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: 'رقم الحساب: (70400000273210)',
      term_en: 'Account: (70400000273210)',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: 'رقم الآيبان: (SA4210000070400000273210',
      term_en: 'IBAN NO: (SA4210000070400000273210)',
      description_ar:'',
      description_en: ''
    },
    {
      term_ar: '3.7',
      term_en: '3.7',
      description_ar:'تقدم كورتينا باقة "Courtena+" وتحتوي هذه الباقة على عدد من الخدمات الموضحة في الجدول المدرج بالبند 8/3، وتكون قيمة مبلغ الاشتراك الشهري (1500 ريال)، غير شامل ضريبة القيمة المضافة للملاعب التي تمتلك فرعًا واحدًا، أما الملاعب التي تمتلك أكثر من فرع فيتم احتساب مبلغ الاشتراك كالآتي:',
      description_en: 'Courtena provides "Courtena+" and this package offers many services mentioned in the clause "3.8" the subscription fee for courts with one branch will be (1500 SR), VAT excluded. As for courts that have multiple branches, the subscription fee will be (……..)'
    },
    {
      term_ar: '4- آلية العمل',
      term_en: '4- Work Process ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '4.1',
      term_en: '4.1',
      description_ar: 'يتم الربط التقني بين الطرفين، ويلتزم المشترك بتزويد كورتينا بجميع البيانات المطلوبة والضرورية حتى يتسنى لها تقديم خدمات تسهيل إجراءات الحجز للعملاء على نحو ملائم.',
      description_en: 'The two parties will integrate technologically, and the subscriber will provide Courtena all the information required, so that he may properly facilitate sport court booking for customers.'
    },
    {
      term_ar: '4.2',
      term_en: '4.2',
      description_ar: 'يلتزم المشترك بتقديم الخدمات المذكورة عبر التطبيق بشكل مرضي للعملاء، وأي نزاع يحدث بين المشترك والعميل حول الخدمة المقدمة من قبله يكون المشترك وحده مسؤولًا عن ذلك.',
      description_en: 'The subscriber is committed to provide the services mentioned in the app, in order to satisfy the customers. And any dispute between the subscriber and the customer about the rendered services, only the subscriber shall bear liability.'
    },
    {
      term_ar: '4.3',
      term_en: '4.3',
      description_ar: 'تتحمل كورتينا الرسوم البنكية للحوالات عدا المذكورة في البندين التالييّن " ٣.٥ " و " ٥.١ "',
      description_en: 'Bank transfer fees are the Courtena responsibility except for those mentioned in the cluses "3.5", "5.1".'
    },
    {
      term_ar: '5- ',
      term_en: '5- ',
      description_ar: 'رد الأموال',
      description_en: 'Refunds'
    },
    {
      term_ar: '5.1',
      term_en: '5.1',
      description_ar: 'يتحمل المشترك رسوم التحويلات البنكية والضرائب التي عليها وأية رسوم عن تحويل المبالغ إلى حساب العميل في حالة طلبه استرداد الأموال.',
      description_en: 'The subscriber shall bear the fees for bank transfers, taxes, and any fees for transferring funds to the customer account.'
    },
    {
      term_ar: '6-  التزامات كورتينا',
      term_en: '6- Courtena Obligations',
      description_ar: '',
      description_en: ' '
    },
    {
      term_ar: '6.1',
      term_en: '6.1',
      description_ar: 'إدراج البيانات الخاصة بالملاعب الرياضية، والحصص الرياضية بمختلف أنواعها الموجودة لدى المشترك، لتكون متاحة للحجز من خلال التطبيق الإلكتروني.',
      description_en: 'Insert the pertinent details about the various sports and the sport courts, to be available for booking via the app.'
    },
    {
      term_ar: '6.2',
      term_en: '6.2',
      description_ar: 'المحافظة على بيانات قاعدة العملاء التابعة للمشترك، ولوحة التحكم الخاصة به والتأكد من منع وصول أي منافس أو طرف ثالث إليها.',
      description_en: 'Preserve the subscriber customers data, and his control panel, further ensure to prevent any competitor or third party from accessing it.'
    },
    {
      term_ar: '6.3',
      term_en: '6.3',
      description_ar: ' تمكين المشترك من خلال مسؤول النظام لديه للدخول على التقارير، واستعراض تقارير الخدمات، والحجوزات اليومية، والأسبوعية، والشهرية، والإيرادات المتحصلة من خلال التطبيق.',
      description_en: 'Enable the subscriber access to daily, weekly, and monthly booking reports as well as the revenue generated via the app through its system administrator.'
    },
    {
      term_ar: '6.4',
      term_en: '6.4',
      description_ar: 'تقديم التدريب للمشترك، ومنسوبيه على كيفية استخدام التطبيق.',
      description_en: 'Instruct the subscriber staff members how to use the app.'
    },
    {
      term_ar: '6.5',
      term_en: '6.5',
      description_ar: 'تقديم الدعم الفني اللازم لاستمرارية عمل التطبيق.',
      description_en: 'Supply the technical assistance required to keep the app running.'
    },
    {
      term_ar: '6.6',
      term_en: '6.6',
      description_ar: 'تكون كورتينا ملزمةَ بتعويض المستخدم (اللاعب) بحجز آخر مماثل في حال ثبت عدم تمكنه من الاستفادة من الحجز الذي تم عبر التطبيق لوجود خلل ناتج عن التطبيق، مع احتفاظ المشترك بمبلغ الحجز.',
      description_en: 'Courtena shall compensate the user (player) with another booking, whereas the subscriber will keep the booking fee if it determined that the user (player) unable to benefit from the booking made through the app due to a problem with it.'
    },
    {
      term_ar: '6.7',
      term_en: '6.7',
      description_ar: 'يحق لكورتينا وقف أو تحسين أو تطوير أو تعديل بعض خدماتها المقدمة وذلك وفقاً لاحتياجاتها و أولوياتها، على أن تقوم بإشعار المشترك في أقرب وقت ممكن في حال نتج عن هذه التغييرات أي تأثير سلبي على استفادة العملاء من خدمات التطبيق.',
      description_en: 'Courtena shall have the right to stop, improve, develop or modify some of its provided services, according to its need and priorities, and shall notify the subscriber as soon as possible, if theses changes result in  any negative impact on the customers benefit from the app services.'
    },
    {
      term_ar: '6.8',
      term_en: '6.8',
      description_ar: 'تتعهد كورتينا بالرد على استفسارات العملاء والشكاوى المتعلقة بالتطبيق خلال (٣) أيام عمل من تاريخ استقبالها كحد أقصى.',
      description_en: 'Courtena shall undertake to respond to customer inquiries, complaints cases within (3) working days from the date of receiving them as a maximum.'
    },
    {
      term_ar: '7- التزامات المشترك',
      term_en: '7- The subscriber Obligations ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '7.1',
      term_en: '7.1',
      description_ar: 'تزويد كورتينا بالقائمة المعتمدة لخدماته التي يريد إتاحتها عبر التطبيق شاملةَ الأسعار وضريبة القيمة المضافة، وجميع البيانات التي تحتاج كورتينا الحصول عليها بالقدر اللازم الذي يوفر للعميل الخدمة.',
      description_en: 'Provide Courtena with the approved list that will be available through the app, including prices and VAT, as well as all the date Courtena needs to deliver the services to the customers.'
    },
    {
      term_ar: '7.2',
      term_en: '7.2',
      description_ar: 'يلتزم المشترك بتقديم الخدمات المعلن عنها عبر التطبيق للمستخدمين، ويعتبر مسؤولًا عن الالتزام الكامل بكافة الأنظمة واللوائح والمقاييس الخاصة بالعمل التابع له، وفقَا للأنظمة المعمول بها في المملكة العربية السعودية.',
      description_en: 'The subscriber committed to provide the services announced through the app to users, and he will be fully accountable for following all the business-related rules. Regulations, and standards in Saudi Arabia.'
    },
    {
      term_ar: '7.3',
      term_en: '7.3',
      description_ar: 'الالتزام بأسعار الخدمات والعروض المعلن عنها وعدم التلاعب بالأسعار.',
      description_en: 'Compliance to advertised offers and services fees, from price manipulation.'
    },
    {
      term_ar: '7.4',
      term_en: '7.4',
      description_ar: 'توفير خدمات الإنترنت والحاسب الآلي وملحقاته للعاملين التابعين له، ليتمكنوا من متابعة الحجوزات، وتحديث الخدمات الخاصة بالفرع بشكل دوري، وللاستفادة من خدمات التطبيق.',
      description_en: 'Provide internet and computer services to its staff, so they can follow up the bookings, update the branch services regularly, and benefit from application features.'
    },
    {
      term_ar: '7.5',
      term_en: '7.5',
      description_ar: 'يلتزم المشترك بإشعار فريق كورتينا فوراً عند حدوث تغيير في اسم العلامة التجارية أو طبيعة/ نوع الخدمات المقدمة عبر التطبيق.',
      description_en: 'The subscriber shall be obliged to notify the Courtena team immediately when there is a change in the brand name, the nature/type of services provided through the app.'
    },
    {
      term_ar: '7.6',
      term_en: '7.6',
      description_ar: 'في حال تخلف المشترك عن تقديم خدماته للعميل، يكون مسؤولًا وحده عن أي مطالبات أو تعويضات مالية.',
      description_en: 'If the subscriber fails to provide the services for customers, he will be solely responsible for any claims or financial compensation.'
    },
    {
      term_ar: '7.7',
      term_en: '7.7',
      description_ar: 'يوافق المشترك على أن كورتينا لا تمنح أي ضمان من أي نوع بالنسبة للخدمات المقدمة في ملاعبه ويتحمل وحده كافة المسؤولية تجاه العميل.',
      description_en: 'The subscriber agrees that Courtena does not give any guarantee of any kind for services provided at the subscriber courts, and he assumes all responsibility towards the customer.'
    },
    {
      term_ar: '7.8',
      term_en: '7.8',
      description_ar: ' إخطار كورتينا على الفور إذا علم بأي وصول غير مصرح به، أو أي خرق فعلي أو محتمل للأمن فيما يتعلق بخدمات التطبيق.',
      description_en: 'Notify Courtena immediately, if he becomes aware of any unauthorized access or any actual or potential breach of security in relation to the app services.'
    },
    {
      term_ar: '8- استخدام الشعار ',
      term_en: '8- Use Of Logo ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '8.1 ',
      term_en: '8.1 ',
      description_ar: 'يوافق الطرفان ويقران بأنه يحق لكل طرف، خلال مدة هذه الاتفاقية تضمين شعار الطرف الآخر للاستخدام المعقول في أي إعلان و/أو تسويق و/أو أدبيات ترويجية تتعلق بخدمات كورتينا شريطة أن يتوافق هذا الاستخدام دومًا للهوية المرئية لهذا الطرف، إلى جانب أي قواعد أو إرشادات سارية للعلامة التجارية.',
      description_en: 'The parties agree and acknowledge that each party shall be entitled, during the term of this agreement to include the other party logo for reasonable usage in any advertisement, marketing and/or promotional literature relating to Courtena services, as contemplated by the terms of this agreement, subject always to such usage complying with the partys visual identity, along with any applicable branding rules and guidelines. '
    },
    {
      term_ar: '9- حدود المسؤولية',
      term_en: '9- Liability Limitation ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '9.1 ',
      term_en: '9.1 ',
      description_ar: 'لا يجوز بأي حال من الأحوال أن تكون كورتينا، أو مسؤولوها، أو مديروها، أو موظفوها، أو وكلاؤها، أو شركاؤها مسؤولين تجاه المشترك عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو تبعية، بما في ذلك على سبيل المثال لا الحصر: إذا كانت الأضرار تنشأ عن استخدام التطبيق أو إساءة استخدامه أو عدم القدرة على استخدام التطبيق أو انقطاع التطبيق أو تعليقه أو تعديله أو تغييره أو إنهائه.',
      description_en: 'In no event shall Courtena or its officers, directors, employees, agents, or partners be liable to the subscriber for any direct, incidental, special, or consequential damages including but not limited to, if the damages arise from the use or misuse of the app or inability to use it or interruption suspension, modification, alteration, or termination of the app.'
    },
    {
      term_ar: '9.2 ',
      term_en: '9.2 ',
      description_ar: 'لن تتحمل كورتينا المسؤولية عن عدم توفر خدمات التطبيق أثناء عمليات الصيانة الدورية أو أي تعليق غير مخطط قد يحدث لأسباب فنية أو لأي سبب خارج عن إرادتها.',
      description_en: 'Courtena shall not be held responsibility for the unavailability of platform services during periodic maintenance operations or any unplanned suspension that may occur for technical reasons or any other reason beyond its control.'
    },
    {
      term_ar: '10- أمن البيانات',
      term_en: '10- Data Security ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '10.1 ',
      term_en: '10.1 ',
      description_ar: 'يجب على كل طرف أن يبذل أفضل مساعيه لحماية بيانات العملاء، وتبني سياسة خصوصية وفقًا لقوانين حماية البيانات المعمول بها في المملكة العربية السعودية.',
      description_en: 'The parties shall use best endeavours to protect user data and adopt a privacy policy in accordance with the data protection laws in Saudi Arabia.'
    },
    {
      term_ar: '11- إبراء الذمة من الضمانات ',
      term_en: '11- Disclaimer of Warranties',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '11.1 ',
      term_en: '11.1 ',
      description_ar: 'يتم توفير خدمات تسهيل إجراءات الحجز عبر التطبيق "كما هي وعلى حالتها" حيث إن كورتينا لا تقدم أي ضمان من أي نوع، صريحًا أو ضمنيًا أو قانونيًا أو غير ذلك، بما في ذلك على سبيل المثال لا الحصر:',
      description_en: 'The facilitate sport court booking through the app are provided "as is". Courtena does not make any warranty of any kind, express, implied, statutory, or otherwise including but not limited to:'
    },
    {
      term_ar: '•	الملاءمة لغرض معين.',
      term_en: '•	Fitness for a particular purpose.',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '•	خلو التطبيق من العيوب.',
      term_en: '•	That the app services are free from any defects. ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '•	زيادة أرباح المشترك.',
      term_en: '•	Increase the profits of the subscriber. ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '12- إنهاء الاتفاقية',
      term_en: '12- Termination of the Agreement',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '12.1 ',
      term_en: '12.1 ',
      description_ar: 'يحق لكورتينا تعليق أو إيقاف الخدمات كليًا أو جزئيًا عن المشترك في الحالات التالية',
      description_en: 'Courtena shall have the right to suspend or discontinue the services in whole or in part from the subscriber in the following cases:'
    },
    {
      term_ar: '•	خرق أو انتهاك المشترك لشروط وأحكام هذا العقد.',
      term_en: '•	Breach or violation by the subscriber of the terms and conditions of this agreement. ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '•	قيام المشترك بأنشطة مشبوهة فيما يتعلق بالخدمات، وذلك وفق ما تراه كورتينا.',
      term_en: '•	Suspicious activities by the subscriber in connection with the services, as deemed by Courtena.',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '13- أحكام عامة',
      term_en: '13- General Provisions ',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '13.1 ',
      term_en: '13.1 ',
      description_ar: 'يتحمل كل طرف من جانبه مسؤولية الالتزام والعمل وفق الأنظمة المعمول بها في المملكة العربية السعودية، والتأكد من توافر جميع التراخيص النظامية، وكل فعل بخلاف ذلك ينتج عنه (مخالفات/عقوبات)، يكون الطرف المتسبب بها، حاملًا لآثارها وحده.',
      description_en: 'Each Party bears the responsibility for its own part to follow and comply with the laws in Saudi Arabia, and to ensure that all required licenses are obtainable, any other action than that results (penalties/violations) the party will be solely accountable for those consequences.'
    },
    {
      term_ar: '13.2 ',
      term_en: '13.2 ',
      description_ar: 'لا يمثل هذا العقد أي شراكة تجارية بين الطرفين في حقوق الملكية، وجميع ما ينتج من أعمال بشكل مستقل مملوكة حصريًا للطرف الذي قدمها وطورها، وعليه فلا تمتلك كورتينا بموجب هذا العقد أي حصة في المشترك، ولا يملك المشترك أي حقوق ملكية فكرية في تطبيق كورتينا.',
      description_en: 'This agreement does not represent any commercial partnership between the two parties. And all independently produced works are owned exclusively by the same party developed it, accordingly with the terms of this agreement Courtena has no ownership interest in the subscriber, nor the subscriber does not own any intellectual property rights at Courtena app.'
    },
    {
      term_ar: '13.3 ',
      term_en: '13.3 ',
      description_ar: 'اتفق الطرفان على أن هذه الاتفاقية حصرية ولا يمكن لأي طرف التنازل عنها إلى جهة أخرى دون الرجوع إلى الطرف الآخر وأخذ موافقته الخطية على ذلك.',
      description_en: 'The two parties agree that this agreement is exclusive and cannot be assigned to another party without referring to the other party and obtaining its written consent for this purpose.'
    },
    {
      term_ar: '13.4 ',
      term_en: '13.4 ',
      description_ar: 'لا يجوز للطرفين -وتحت أي ظرف- إفشاء المعلومات التي تحصل عليها بمقتضى هذه الاتفاقية، لطرف ثالث، عدا مستشاريه الذين لهم علاقة مباشرة ببنود العقد، ويستثنى من ذلك الإفصاح للسلطات المختصة أو المحكمة عند الاقتضاء بالقدر اللازم الذي يحقق الامتثال لهم.',
      description_en: 'The two parties may not – under any circumstances disclose the information to a third party. with the exception of their advisors who are directly involved in the terms of this agreement, this exclude disclosure to the authorities or the court if needed and shall be to the extent that compliance is achieved.'
    },
    {
      term_ar: '13.5 ',
      term_en: '13.5 ',
      description_ar: 'التقويم الميلادي هو التقويم المعتمد لتنفيذ هذه الاتفاقية، بحيث تحتسب جميع التواريخ والمدد وفقاً لذلك.',
      description_en: 'The Gregorian calendar is the approved calendar for the implementation of this contract, thereby all dates and periods are calculated accordingly.'
    },
    {
      term_ar: '13.6 ',
      term_en: '13.6 ',
      description_ar: 'لا يحق لأي من الطرفين التمسك بشروط لم يسبق الاتفاق عليها كتابة.',
      description_en: 'Neither party shall be entitled to uphold terms that have not previously been agreed upon in writing.'
    },
    {
      term_ar: '13.7 ',
      term_en: '13.7 ',
      description_ar: 'لا يؤثر بطلان أي حكم أو شرط أو نص في هذه الاتفاقية على سريان باقي الأحكام والشروط والنصوص الواردة بها، ما لم يكن هذا الشرط جوهرياً ويتعلق بالغرض الرئيسي من توقيع هذه الاتفاقية.',
      description_en: 'The invalidity of any provision, condition, or provision of this agreement shall not affect the validity of the remaining terms, conditions, and provisions contained therein unless such condition is essential and relates to the main purpose of signing this agreement.'
    },
    {
      term_ar: '13.8 ',
      term_en: '13.8 ',
      description_ar: 'على الطرفين بذل قصارى الجهد لتنفيذ بنود وأحكام هذه الاتفاقية وفقاً لمبدأ حسن النية وما تقتضيه عناية الشخص الحريص من الجد والجهد اللازمين لتحقيق مصالح الطرفين المشتركة.',
      description_en: 'The two Parties shall make every effort to implement the terms and conditions of this agreement in accordance with the principle of good faith and diligence and effort necessary to achieve the common interests of the two Parties.'
    },
    {
      term_ar: '13.9 ',
      term_en: '13.9 ',
      description_ar: 'إن هذه الاتفاقية تعلق حكماً عند حصول ما يمكن اعتباره قوة قاهرة ويعود فيستمر عند زوالها، وعلى من يتذرع بالقوة القاهرة أو انقضائها أن يثبت ذلك.',
      description_en: 'This agreement shall be suspended by virtue of a provision when what may be considered force majeure occurs and returns and continues upon its disappearance, and the person who invokes force majeure or its expiry shall prove it.'
    },
    {
      term_ar: '14- تسوية الخلافات',
      term_en: '14- Dispute Resolution',
      description_ar: '',
      description_en: ''
    },
    {
      term_ar: '14.1 ',
      term_en: '14.1 ',
      description_ar: 'في حال نشوء نزاع عن هذه الاتفاقية فيتم حلها ودياً بين الطرفين خلال (١٥) يوماً من تاريخ نشوئها، وفي حال تعذر ذلك يتم اللجوء للجهات القضائية المختصة في مدينة جدة.',
      description_en: 'In the event that a dispute arises about this agreement, it shall be resolved amicably between the two parties within 15 (days) from the date of its emergence, and if this is not possible, resort shall be made to the competent judicial authorities in the city of Jeddah.'
    },


  ];

  let navigate = useNavigate();
  const partnersTableData = {
    columns: [
      { name: "name", align: "center" },
      { name: "time_range", align: "center" },
      // { name: "courts", align: "center" },
      { name: "action", align: "center" },
    ],
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const { columns } = partnersTableData;
  const handleSubmit = async (e) => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    const data = { companyName: companyName, email: companyEmail, taxInformation: { taxIDType: taxIdType, taxNumber: taxNumber, vatTax: vatTax }, companyAddress: { streetAdrress: streetAdrress, postalCode: postalCode, city: city, country: country }, bankInformation: { iban: iban, bicSwift: bicSwift }, partner: partnerInfo._id }
    setBackdrop(true)
    if (edit) {

      await courtena.put("/partner/billing/" + editId + "/update", { ...data }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*',
          'Authorization': partnerInfo.token
        }
      }).then((response) => {

        if (response.data.success) {
          setBackdrop(false)
          setSuccess(true)
          setSuccessMessage(response.data.message)
        } else {
          setBackdrop(false)
          setError(true)
          setErrorMessage(response.data.message)
        }

      }).catch(err => console.log(err.message));
    } else {
      await courtena.post("/partner/billing/create/", { ...data }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*',
          'Authorization': partnerInfo.token
        }
      }).then((response) => {

        if (response.data.success) {
          setBackdrop(false)
          setSuccess(true)
          setSuccessMessage(response.data.message)
        } else {
          setBackdrop(false)
          setError(true)
          //   setErrorMessage(response.data.message)
        }

      }).catch(err => console.log(err.message));
    }


  }
  async function getBillingInfo() {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    setBackdrop(true)
    // const data = {name:name,city:city,address:address,description:description,cheapestPrice:price,venuePhone:contactNum,postalCode:1234,amenities:{cafeteria:cafeteria,changeRoom:changingRoom,disabledAccess:disabledAccess,freeParking:freeParking,lockers:lockers,materialRenting:materialRenting,privateParking:privateParking,restaurant:restaurant,snackbar:snackbar,store:store,vendingMachine:vendingMachine,wifi:wifi},timing:{mondayOn:mondayOpen,mondayFrom:mondayFrom,mondayTo:mondayTo,tuesdayOn:tuesdayOpen,tuesdayFrom:tuesdayFrom,tuesdayTo:tuesdayTo,wedOn:wednesdayOpen,wedFrom:wedFrom,wedTo:wedTo,thursdayOn:thursdayOpen,thursdayFrom:thursdayFrom,thursdayTo:thursdayTo,fridayOn:fridayOpen,fridayFrom:friFrom,fridayTo:friTo,satOn:saturdayOpen,satFrom:satFrom,satTo:satTo,sunOn:sundayOpen,sunFrom:sunFrom,sunTo:sunTo,holidayOn:holidayOpen,holidayFrom:holidayFrom,holidayTo:holidayTo},partner:partnerInfo._id}
    await courtena.get("/partner/billing/billing-info/" + partnerInfo._id, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
      }
    }).then((response) => {
      if (response.data.success) {
        let newPricing = []
        if (response.data.result !== null) {
          setCompanyName(response.data.result.companyName)
          setCompanyEmail(response.data.result.email)
          setTaxIdType(response.data.result.taxInformation.taxIDType)
          setTaxNumber(response.data.result.taxInformation.taxNumber)
          setVatTax(response.data.result.taxInformation.vatTax)
          setIban(response.data.result.bankInformation.iban)
          setBicSwift(response.data.result.bankInformation.bicSwift)
          setStreetAddress(response.data.result.companyAddress.streetAdrress)
          setPostalCode(response.data.result.companyAddress.postalCode)
          setCountry(response.data.result.companyAddress.country)
          setCity(response.data.result.companyAddress.city)
          setEditId(response.data.result._id)
          setEdit(true)
        } else {
          setEdit(false)
        }
        setBackdrop(false)

      } else {
        setBackdrop(false)
        setError(true)
        setErrorMessage(response.data.message)
      }

    }).catch(err => console.log(err));
  }
  async function getPricingPlans() {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    setBackdrop(true)
    // const data = {name:name,city:city,address:address,description:description,cheapestPrice:price,venuePhone:contactNum,postalCode:1234,amenities:{cafeteria:cafeteria,changeRoom:changingRoom,disabledAccess:disabledAccess,freeParking:freeParking,lockers:lockers,materialRenting:materialRenting,privateParking:privateParking,restaurant:restaurant,snackbar:snackbar,store:store,vendingMachine:vendingMachine,wifi:wifi},timing:{mondayOn:mondayOpen,mondayFrom:mondayFrom,mondayTo:mondayTo,tuesdayOn:tuesdayOpen,tuesdayFrom:tuesdayFrom,tuesdayTo:tuesdayTo,wedOn:wednesdayOpen,wedFrom:wedFrom,wedTo:wedTo,thursdayOn:thursdayOpen,thursdayFrom:thursdayFrom,thursdayTo:thursdayTo,fridayOn:fridayOpen,fridayFrom:friFrom,fridayTo:friTo,satOn:saturdayOpen,satFrom:satFrom,satTo:satTo,sunOn:sundayOpen,sunFrom:sunFrom,sunTo:sunTo,holidayOn:holidayOpen,holidayFrom:holidayFrom,holidayTo:holidayTo},partner:partnerInfo._id}
    await courtena.get("/admin/subscriptions/", {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
      }
    }).then((response) => {
      if (response.data.success) {
        let newPricing = []
        response.data.result.map((item) => {
          newPricing.push(item)
        })
        setPricing(newPricing)
        setBackdrop(false)

      } else {
        setBackdrop(false)
        setError(true)
        setErrorMessage(response.data.message)
      }

    }).catch(err => console.log(err));
  }
  useEffect(() => {
    getBillingInfo()
    getPricingPlans()

  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar light={true} />
      <Dialog
        open={tc}
        // onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent dividers={'paper'}>
        {termsAndConditions.map((item, index) => (
      <React.Fragment key={index}>
        <DialogContentText id={`scroll-dialog-description-${index}`} tabIndex={-1}>
          <strong>{item.term_ar}</strong>: {item.description_ar}
        </DialogContentText>
        <DialogContentText id={`scroll-dialog-description-${index}`} tabIndex={-1}>
          <strong>{item.term_en}</strong>: {item.description_en}
        </DialogContentText>
      </React.Fragment>
    ))}
        </DialogContent>
        <DialogActions>
          {/* <SoftButton >Cancel</SoftButton> */}
          <SoftButton onClick={() => {setTC(false)}}>Agree</SoftButton>
        </DialogActions>
      </Dialog>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {/* <Table columns={columns} rows={pricing} /> */}
              <SoftBox sx={{ width: '100%' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Tabs">
                  {/* <Tab label="Billing Information" /> */}
                  <Tab label="Pricing Plans" />
                </Tabs>
                
                {selectedTab === 0 && (
                  <SoftBox display="flex" justifyContent="center" alignItems="center" height="auto" sx={{ padding: 5 }}>

                    {pricing.map((item) => {
                      return (
                        <Card key={item._id} sx={{ maxWidth: 300 }}>

                          <CardContent>
                            <SoftTypography variant="h5" component="div" gutterBottom>
                              {item.name}
                            </SoftTypography>
                            <SoftTypography variant="subtitle1" color="text.secondary" gutterBottom>
                              SAR {item.price}/month
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text.secondary">
                              {item.description}
                            </SoftTypography>
                            <SoftButton onClick={async () => {
                              var partnerInfoString = localStorage.getItem("partner")
                              var partnerInfo = JSON.parse(partnerInfoString)
                              // const data = { amount: 170000, description: "Subscription for courtena partner platform", success_url: "http://localhost:3000/payment/success", callback_url: "http://localhost:3000/payment/success", currency: "SAR" }
                              const data = {name:item.name,subscriptionType:item._id,partner:partnerInfo._id,amount: item.price*100, description: "Subscription for courtena partner platform", success_url: "https://partner.courtena.com/payment/success", callback_url: "https://partner.courtena.com/payment/success", currency: "SAR" }
                              await courtena.post("/partner/subscription/create",{...data},{
                                  headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Accept': '*/*',
                                    'Authorization': partnerInfo.token
                                }
                                }).then((res) => {
                                  if(res.data.success){
                                    window.open(res.data.result.payment.url, '_self');
                                  }
                                      // const myObject = JSON.parse(localStorage.getItem('partner'));
                                      // myObject.isSubscribed = res.data.result.partner.isSubscribed;
                                      // localStorage.setItem('partner', JSON.stringify(myObject));
                                }).catch(err => {
                                      console.log(err)
                                })
                              
                            }} variant="contained" fullWidth>
                              Subscribe
                            </SoftButton>
                          </CardContent>
                        </Card>)
                    })}
                  </SoftBox>
                )}
              </SoftBox>

            </SoftBox>
          </Card>
        </SoftBox>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Subscribe;
