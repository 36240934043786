// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    scrollbar-width: thin;
    scrollbar-color: #2c3856;
}

/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #a5d9f3;
    border-radius: 5px;
    border: 1px solid #a5d9f3;
}

.selectArrow {
    color: red; /* Specify the desired color here */
  }

.cursor-pointer{
    cursor:pointer;
}  
.pac-container {
    z-index: 10000; 
    position: absolute !important; 
  }

  .custom-toast {
    font-size: 16px; /* Adjust the font size as needed */
  }`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA,wCAAwC;AACxC;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,UAAU,EAAE,mCAAmC;EACjD;;AAEF;IACI,cAAc;AAClB;AACA;IACI,cAAc;IACd,6BAA6B;EAC/B;;EAEA;IACE,eAAe,EAAE,mCAAmC;EACtD","sourcesContent":["* {\n    scrollbar-width: thin;\n    scrollbar-color: #2c3856;\n}\n\n/* Targtes on Chrome, Edge, and Safari */\n*::-webkit-scrollbar {\n    width: 8px;\n}\n\n*::-webkit-scrollbar-track {\n    background: #ffffff;\n}\n\n*::-webkit-scrollbar-thumb {\n    background-color: #a5d9f3;\n    border-radius: 5px;\n    border: 1px solid #a5d9f3;\n}\n\n.selectArrow {\n    color: red; /* Specify the desired color here */\n  }\n\n.cursor-pointer{\n    cursor:pointer;\n}  \n.pac-container {\n    z-index: 10000; \n    position: absolute !important; \n  }\n\n  .custom-toast {\n    font-size: 16px; /* Adjust the font size as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
