/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import partnersTableData from "layouts/partners/data/partnersTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Backdrop, Button, CardContent, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, InputLabel, MenuItem, Radio, RadioGroup, Select, Tab, TableBody, TableCell, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SoftButton from "components/SoftButton";
// import partnersTableData from "./data/partnersTableData";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import courtena from "api/courtena";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Resource from '@fullcalendar/resource';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import PropTypes from 'prop-types'; // Import PropTypes
import moment from "moment"
import styled from "@emotion/styled";
import { ArrowDropDown } from "@mui/icons-material";
import SoftBadge from "components/SoftBadge";

export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary{
    background: #2c3856;
    background-image: none;
    border-radius:20px;
    margin:5px
}
.fc-toolbar-title{
  color:#2c3856;
  font-size:20px
}
.fc-col-header-cell-cushion{
  color:#2c3856;
  font-size:12px
}
.fc-timegrid-axis-cushion{
  color:#2c3856;
  font-size:12px
}
.fc-timegrid-slot-label-cushion{
  color:#2c3856;
  font-size:12px
}
.fc-col-header-cell-cushion span{
  color:#2c3856;
  font-size:10px
}
.fc-timegrid-slot {
  /* Adjust the height of each time slot */
  height: 50px;
}

.fc-resource-cell {
  /* Adjust the width of each resource (court) column */
  width: 250px;
}

.fc-axis {
  /* Adjust the width of the left axis containing time labels */
  width: 250px;
}

.fc-resource-timegrid-view .fc-timegrid-slots tr > td {
  /* Adjust the height of each row in resourceTimeGridWeek */
  height: 50px;
}

.fc-resource-timegrid-view .fc-timegrid-slots .fc-timegrid-slot {
  /* Adjust the height of each time slot in resourceTimeGridWeek */
  height: 50px;
}

.fc-resource-timegrid-view .fc-col-header-cell {
  /* Adjust the width of each day column in resourceTimeGridWeek */
  width: 250px;
}
.fc-col-header-cell-cushion .fc-timegrid-axis .fc-scrollgrid-sync-inner{
  width:300px
}
.fc-day-today {
  background-color: white !important;
}
.fc-license-message{
  width:0;
  height:0
}
`

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

function Bookings() {
  const classes = useStyles();
  // const { columns, rows } = partnersTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [bookings, setBookings] = useState()
  const [courts, setCourts] = useState([])
  const [selectedCourt, setSelectedCourt] = useState("")
  const [selectedTime, setSelectedTime] = useState([])
  const [timings, setTimings] = useState([])
  const [venueCourts, setVenueCourts] = useState([])
  const [venues, setVenues] = useState([])
  const [selectedVenue, setSelectedVenue] = useState("")
  const [selectedPrice, setSelectedPrice] = useState(0)
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedDateCal, setSelectedDateCal] = useState("")
  const [manualContactInfo, setManualContactInfo] = useState("")
  const [blockTimeInfo, setBlockTimeInfo] = useState("")
  const [selectedDuration, setSelectedDuration] = useState()
  const [durations, setDurations] = useState([])
  const [tablebookings, setTableBookings] = useState()
  const [backdrop, setBackdrop] = useState(false)
  const [manualBooking, setManualBooking] = useState(false)
  const [manualBlockBooking, setManualBlockBooking] = useState(false)
  const [manualBlockBookingInfoModal, setManualBlockBookingInfoModal] = useState(false)
  const [manualBookingInfoModal, setManualBookingInfoModal] = useState(false)
  const [bookingInfoModal, setBookingInfoModal] = useState(false)
  const [bookingDetails, setBookingDetails] = useState()
  const [courtDetails, setCourtDetails] = useState()
  const [venueDetails, setVenueDetails] = useState()
  const [customerDetails, setCustomerDetails] = useState()
  const [calEvents, setCalEvents] = useState()
  const [viewType, setViewType] = useState("calendar")
  const today = new Date().toISOString().split('T')[0];


  let navigate = useNavigate();


  // Example data for times
  const times = ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];
  const partnersTableData = {
    columns: [
      { name: "customer", align: "center" },
      { name: "status", align: "center" },
      { name: "venue", align: "center" },
      { name: "court", align: "center" },
      { name: "duration", align: "center" },
      { name: "date", align: "center" },
      { name: "time", align: "center" },
      { name: "payment", align: "center" },
      { name: "payment_status", align: "center" },
      { name: "invoice", align: "center" },
    ],
  };
  const { columns } = partnersTableData;
  const parseDuration = (duration) => {
    const match = duration.match(/^(\d+)(min)$/);
    if (match) {
      const minutes = parseInt(match[1], 10);
      return minutes;
    }
    return 0; // Default to 0 minutes if the format is not as expected
  };

  const checkBookingConflict = (bookings, selectedDate, selectedTime, selectedDuration, selectedVenue, selectedCourt) => {
    const selectedStartTime = selectedTime;
    const selectedEndTime = addIntervalToTime(selectedTime, selectedDuration);

    for (const booking of bookings) {
      const bookingDate = booking.booking.date;
      const bookingStartTime = booking.booking.dateTimeInfo.timeFrom;
      const bookingEndTime = booking.booking.dateTimeInfo.timeTo;
      const bookingVenue = booking.booking.venue;
      const bookingCourt = booking.booking.court;

      if (
        selectedDate === bookingDate &&
        selectedStartTime < bookingEndTime &&
        selectedEndTime > bookingStartTime &&
        selectedVenue === bookingVenue &&
        selectedCourt === bookingCourt
      ) {
        // There is a conflict
        return true;
      }
    }

    // No conflict found
    return false;
  };

  const createManualBooking = async () => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    const { year, month, day } = extractDateParts(selectedDate);
    setBackdrop(true)
    const data = { bookingData: { duration: selectedDuration, date: selectedDate, time: selectedTime, manualContactInfo: manualContactInfo, dateTimeInfo: { day: day, month: month, year: year, timeFrom: selectedTime, timeTo: addIntervalToTime(selectedTime, selectedDuration) }, manualBooking: true, paymentAmount: selectedPrice, payment: { id: "manual", status: "not paid", currency: "SAR", amount: selectedPrice * 100, amount_format: `${selectedPrice}.00 SAR` }, partner: partnerInfo._id, venue: selectedVenue, court: selectedCourt } }
    // Usage in your createManualBooking function
    const isConflict = checkBookingConflict(tablebookings, selectedDate, selectedTime, selectedDuration, selectedVenue, selectedCourt);
    if (isConflict) {
      setBackdrop(false)
      alert('Selected time conflicts with an existing booking. Please choose another time.');
    } else {
      await courtena.post("/partner/create-partner-manual-booking/", { ...data }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*',
          'Authorization': partnerInfo.token
        }
      }).then((res) => {

        if (res.data.success) {
          setManualBooking(false)
          getPartnerBookings()
          setSelectedVenue("")
          setSelectedCourt("")
        } else {

        }
      }).catch((err) => {

      })
    }


  }

  const createBlockBooking = async () => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    const { year, month, day } = extractDateParts(selectedDate);
    setBackdrop(true)
    const data = { bookingData: { duration: selectedDuration, date: selectedDate, time: selectedTime, blockTimeInfo: blockTimeInfo, dateTimeInfo: { day: day, month: month, year: year, timeFrom: selectedTime, timeTo: addIntervalToTime(selectedTime, selectedDuration) }, manualBooking: true, blockBooking: true, paymentAmount: selectedPrice, payment: { id: "manual", status: "not paid", currency: "SAR", amount: selectedPrice * 100, amount_format: `${selectedPrice}.00 SAR` }, partner: partnerInfo._id, venue: selectedVenue, court: selectedCourt } }
    const isConflict = checkBookingConflict(tablebookings, selectedDate, selectedTime, selectedDuration, selectedVenue, selectedCourt);

    if (isConflict) {
      setBackdrop(false)
      alert('Selected time conflicts with an existing booking. Please choose another time.');
    } else {
      await courtena.post("/partner/create-partner-manual-block-booking/", { ...data }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*',
          'Authorization': partnerInfo.token
        }
      }).then((res) => {
        if (res.data.success) {
          setManualBlockBooking(false)
          getPartnerBookings()
          setSelectedVenue("")
          setSelectedCourt("")
        } else {

        }
      }).catch((err) => {

      })
    }


  }

  function extractDateParts(dateString) {
    const [year, month, day] = dateString.split('/').map(Number);
    return { year, month, day };
  }

  const getPartnerBookings = async () => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    setBackdrop(true)
    await courtena.get("/partner/get-partner-bookings/" + partnerInfo._id, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
      }
    }).then((res) => {
      setBackdrop(false)
      if (res.data.success) {
        let newBookings = []
        res.data.result.resolvedBookings.map((item) => {
          newBookings.push({
            customer: (item.customer ? (
              <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography variant="button" fontWeight="medium">
                    {item.customer ? item.customer.first_name + " " + item.customer.last_name : ""}
                  </SoftTypography>
                  <SoftTypography variant="caption" color="secondary">
                    {item.customer ? item.customer.email : ""}
                  </SoftTypography>
                  <SoftTypography variant="caption" color="secondary">
                    {item.customer ? item.customer.phone : ""}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            ) : <SoftTypography variant="button" fontWeight="medium">
              {item.booking.manualContactInfo ? item.booking.manualContactInfo : ""}
            </SoftTypography>),
            status: (<Chip label={item.booking.status} />),
            venue: (<Chip label={item.venue.name} />),
            court: (<Chip label={item.court.title} />),
            duration: (<Chip label={item.booking.duration} />),
            date: (<Chip label={item.booking.date} />),
            time: (<Chip label={item.booking.dateTimeInfo.timeFrom + "-" + item.booking.dateTimeInfo.timeTo} />),
            payment: (<Chip label={item.booking.payment.amount_format} />),
            payment_status: (<Chip label={item.booking.payment.status} />),
            invoice: (item.booking?.invoice_url ? (<SoftBadge badgeContent="Download" onClick={() => window.open(item?.booking?.invoice_url, '_blank')} />) : null)
          })
        })
        setTableBookings(res.data.result.resolvedBookings)
        setCourts(res.data.result.courts)
        setVenues(res.data.result.venues)
        setBookings(newBookings)
        const events = res.data.result.resolvedBookings?.map((item) => {
          const startDateString = `${item.booking.date}T${item.booking.dateTimeInfo.timeFrom}`;
          const endDateString = `${item.booking.date}T${item.booking.dateTimeInfo.timeTo}`;

          const startDate = moment(startDateString, 'YYYY/MM/DDTHH:mm');
          const endDate = moment(endDateString, 'YYYY/MM/DDTHH:mm');
          if ((item.booking.manualBooking || item.booking.blockBooking) && item.customer === null) {
            return {
              title: item.booking.blockBooking && item.booking.manualBooking ? "Blocked Slot" : "Manual Booking",
              start: startDate.isValid() ? startDate.toDate() : null,
              end: endDate.isValid() ? endDate.toDate() : null,
              resourceId: item.booking.court,
              blocked: item.booking.blockBooking,
              manual: item.booking.manualBooking,
              bookingData: item.booking,
              courtInfo: item.court,
              venueInfo: item.venue
            };
          } else {
            return {
              title: `${item?.customer?.first_name ? item?.customer?.first_name : ""} ${item?.customer?.last_name ? item?.customer?.last_name : ""}`,
              start: startDate.isValid() ? startDate.toDate() : null,
              end: endDate.isValid() ? endDate.toDate() : null,
              resourceId: item.booking.court,
              blocked: item.booking.blockBooking,
              manual: item.booking.manualBooking,
              bookingData: item.booking,
              customerData: item.customer,
              courtInfo: item.court,
              venueInfo: item.venue
            };
          }
        })
        setCalEvents(events)
      } else {
        console.log(res.data.message)
      }
    }).catch((err) => {
      setBackdrop(false)
      console.log(err)
    })
  }

  const deleteBooking = async (bookingid) => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    const { year, month, day } = extractDateParts(selectedDate);
    setBackdrop(true)
    const data = {}
    await courtena.post("/partner/delete-partner-manual-block-booking/" + bookingid, { ...data }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': partnerInfo.token
      }
    }).then((res) => {
      if (res.data.success) {
        getPartnerBookings()
      } else {

      }
    }).catch((err) => {

    })
  }

  const [currentView, setCurrentView] = useState('timeGridDay');

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  const handleEventClick = (arg) => {
    // arg will contain information about the clicked event
    const resourceId = arg.event._def.resourceIds[0];
    setBookingDetails(arg.event._def.extendedProps.bookingData)
    setCourtDetails(arg.event._def.extendedProps.courtInfo)
    setVenueDetails(arg.event._def.extendedProps.venueInfo)

    if (arg.event._def.extendedProps.blocked && arg.event._def.extendedProps.manual) {
      setManualBlockBookingInfoModal(true)
    } else if (arg.event._def.extendedProps.manual) {
      setManualBookingInfoModal(true)
    } else {
      setCustomerDetails(arg.event._def.extendedProps.customerData)
      setBookingInfoModal(true)
    }
  };

  const handleDateSelect = (selectInfo) => {
    let title = alert('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }

  useEffect(() => {
    getPartnerBookings()
  }, [])

  function generateTimings(startTime, endTime) {
    const intervals = [];

    // Parse the start and end times
    const start = parseTime(startTime);
    const end = parseTime(endTime);

    // Loop through the time intervals with 30-minute increments
    let current = start;
    while (current <= end) {
      intervals.push(formatTime(current));
      current = addMinutes(current, 30);
    }

    return intervals;
  }

  // Helper function to parse time in "HH:mm" format
  function parseTime(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return new Date(2000, 0, 1, hours, minutes); // Using 2000-01-01 as the date
  }

  // Helper function to format time in "HH:mm" format
  function formatTime(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  // Helper function to add minutes to a date
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }




  const resources = courts.map((court) => ({
    id: court._id,
    title: court.title,
  }));



  const eventContent = (arg) => {
    if (arg.event._def.extendedProps.blocked && arg.event._def.extendedProps.manual) {
      return (
        <div className="custom-event">
          <div>
            <strong style={{ color: '#fff', fontSize: '10px' }}>
              {/* {moment(arg.event.start).format('ha')} - {moment(arg.event.end).format('ha')} */}
              {formatAMPMTime(arg.event._def.extendedProps.bookingData.dateTimeInfo.timeFrom)} - {formatAMPMTime(arg.event._def.extendedProps.bookingData.dateTimeInfo.timeTo)}
            </strong></div>
          {/* <br /> */}
          <div>
            <span style={{ color: '#fff', fontSize: '10px' }}>   Blocked</span>
          </div>
          <div>
            <span style={{ color: '#fff', fontSize: '10px' }}>({arg.event._def.extendedProps.bookingData.blockTimeInfo})</span>
          </div>
        </div>
      );

    } else if (arg.event._def.extendedProps.manual) {
      return (
        <div className="custom-event">
          <div>
            <strong style={{ color: '#fff', fontSize: '10px' }}>
              {/* {moment(arg.event.start).format('ha')} - {moment(arg.event.end).format('ha')} */}
              {formatAMPMTime(arg.event._def.extendedProps.bookingData.dateTimeInfo.timeFrom)} - {formatAMPMTime(arg.event._def.extendedProps.bookingData.dateTimeInfo.timeTo)}

            </strong>
          </div>
          {/* <br /> */}
          <div>
            <span style={{ color: '#fff', fontSize: '10px' }}>   Manual Booking</span>
          </div>
          <div>
            <span style={{ color: '#fff', fontSize: '10px' }}> Contact: ({arg.event._def.extendedProps.bookingData.manualContactInfo})</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="custom-event">
          <div>
            <strong style={{ color: '#fff', fontSize: '10px' }}>
              {/* {moment(arg.event.start).format('ha')} - {moment(arg.event.end).format('ha')} */}
              {formatAMPMTime(arg.event._def.extendedProps.bookingData.dateTimeInfo.timeFrom)} - {formatAMPMTime(arg.event._def.extendedProps.bookingData.dateTimeInfo.timeTo)}

            </strong></div>
          {/* <br /> */}
          <div>
            <span style={{ color: '#fff', fontSize: '10px' }}>   Reserved by ({arg.event._def.extendedProps.customerData.first_name} {arg.event._def.extendedProps.customerData.last_name})</span>
          </div>
          <div>
            <span style={{ color: '#fff', fontSize: '10px' }}>   Phone: ({arg.event._def.extendedProps.customerData.phone})</span>
          </div>
        </div>
      );
    }

  };

  const eventDidMount = (arg) => {
    // Customize event styles after it's rendered
    if (arg.event._def.extendedProps.blocked && arg.event._def.extendedProps.manual) {
      const eventElement = arg.el;
      eventElement.style.backgroundColor = '#911f21';
      eventElement.style.border = '1px solid #911f21';

    } else if (arg.event._def.extendedProps.manual) {
      const eventElement = arg.el;
      eventElement.style.backgroundColor = '#67d6ee';
      eventElement.style.border = '1px solid #67d6ee';
    } else {
      const eventElement = arg.el;
      eventElement.style.backgroundColor = '#5a688d';
      eventElement.style.border = '1px solid #2c3856';
    }

  };

  const dayHeaderContent = (arg) => {
    // Customize the content of the day headers
    return <span style={{ color: 'red', fontSize: '10px' }}>{arg.view.type === 'timeGridDay' ? 'Courts' : arg.date.toLocaleDateString('en-US', { weekday: 'long' })}</span>;
  };

  const resourceHeaderContent = (arg) => {
    // Customize the content of the resource headers
    return <span style={{ color: 'purple', fontSize: '14px' }}>{arg.resource.title}</span>;
  };
  const handleRadioChange = (event) => {
    const getIntervalItem = durations.find((item) => item.interval === event.target.value)
    setSelectedDuration(event.target.value);
    setSelectedPrice(getIntervalItem.price)
  };

  // Function to add interval to time
  function addIntervalToTime(startTime, interval) {
    // Ensure interval is defined
    if (!interval) {
      return startTime; // Return the start time if interval is not defined
    }

    // Extract numeric part from the interval string
    const intervalInMinutes = parseInt(interval.replace(/\D/g, ''));

    const [startHour, startMinute] = startTime.split(':').map(Number);

    // Calculate the ending time
    let endHour = startHour;
    let endMinute = startMinute + intervalInMinutes;

    // Handle overflow of minutes
    if (endMinute >= 60) {
      endHour += Math.floor(endMinute / 60);
      endMinute %= 60;
    }

    // Format the ending time
    const formattedEndHour = endHour.toString().padStart(2, '0');
    const formattedEndMinute = endMinute.toString().padStart(2, '0');

    return `${formattedEndHour}:${formattedEndMinute}`;
  }

  const handleDateChange = (event) => {
    const newDate = event.target.value;

    // Parse the selected date
    const parsedDate = new Date(newDate);

    // Format the date as "yyyy/mm/dd"
    const formattedDate = `${parsedDate.getFullYear()}/${(parsedDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${parsedDate.getDate().toString().padStart(2, '0')}`;

    setSelectedDate(formattedDate);

    // Do something with the selected date, if needed
  };

  const fullCalendarRef = useRef(null);

  const handleSelectDateChange = (event) => {
    const newDate = event.target.value;

    // Parse the selected date
    const parsedDate = new Date(newDate);

    // Check if the parsed date is valid
    if (!isNaN(parsedDate.getTime())) {
      // Use UTC methods for FullCalendar
      const utcYear = parsedDate.getUTCFullYear();
      const utcMonth = parsedDate.getUTCMonth();
      const utcDay = parsedDate.getUTCDate();

      // Create a new Date object in UTC
      const utcFormattedDate = new Date(Date.UTC(utcYear, utcMonth, utcDay));

      setSelectedDateCal(utcFormattedDate.toISOString()); // Use ISO string for FullCalendar
      if (fullCalendarRef.current) {
        fullCalendarRef.current.getApi().gotoDate(utcFormattedDate);
      }

      // Do something with the selected date, if needed
    } else {
      console.error('Invalid Date');
    }
  };

  function formatReadableDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  }

  function formatAMPMTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    const formattedTime = new Date(0, 0, 0, hours, minutes).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return formattedTime;
  }



  return (
    <DashboardLayout>
      <DashboardNavbar light={true} />
      <SoftBox display="flex"
        justifyContent="center"
        alignItems="center">
        {/* <Card> */}
        <Tabs style={{ backgroundColor: "#2c3856", width: "20%" }} indicatorColor="#000" value={viewType} onChange={(e, v) => setViewType(v)} aria-label="Tabs">
          <Tab label="Calendar" value="calendar" style={{ flex: 1, backgroundColor: viewType === "calendar" ? "#d2ecf9" : "#fff", marginRight: 10 }} />
          <Tab label="List" value="list" style={{ flex: 1, backgroundColor: viewType === "list" ? "#d2ecf9" : "#fff" }} />
        </Tabs>
        {/* </Card> */}
      </SoftBox>
      <SoftBox py={3}>
        <Dialog open={manualBooking}>
          <DialogTitle>Manual Booking</DialogTitle>
          <DialogContent>
            <Container className={classes.container}>
              {/* Section 1: Venue, Court, Date, and Time */}
              <div style={{ marginBottom: '15px' }}>
                <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  <Typography style={{ fontSize: '12px' }}>Venue</Typography>
                  <Select IconComponent={() => <ArrowDropDown />} onChange={(e) => {
                    setSelectedVenue(e.target.value)
                    const filteredVenueCourts = courts.filter(court => court.venue === e.target.value);
                    setVenueCourts(filteredVenueCourts)

                  }}>
                    {venues.map((item) => {
                      return (
                        <MenuItem key={item._id} value={item._id} style={{ color: "#000", width: "100%" }}>{item.name}</MenuItem>
                      )
                    })}

                    {/* Add more venues as needed */}
                  </Select>
                </FormControl>

                {selectedVenue ? <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  <Typography style={{ fontSize: '12px' }}>Court</Typography>
                  <Select IconComponent={() => <ArrowDropDown />} onChange={(e) => {
                    setSelectedCourt(e.target.value)
                    const selectedCourtItem = courts.find(court => court._id === e.target.value);
                    const generatedTimings = generateTimings(selectedCourtItem.pricing.dateTime.startTime, selectedCourtItem.pricing.dateTime.endTime)
                    setTimings(generatedTimings)
                    setDurations(selectedCourtItem.pricing.pricing)
                  }}>
                    {venueCourts.map((item) => {
                      if (item.venue === selectedVenue) {
                        return (
                          <MenuItem key={item._id} value={item._id} style={{ color: "#000", width: "100%" }}>{item.title}</MenuItem>
                        )
                      }
                    })}
                    {/* Add more courts as needed */}
                  </Select>
                </FormControl> : null}


                {timings ? <FormControl className={classes.formControl}>
                  <Typography style={{ fontSize: '12px' }}>Time</Typography>
                  <Select IconComponent={() => <ArrowDropDown />} onChange={(e) => {
                    setSelectedTime(e.target.value)
                  }}>
                    {timings.map((item) => {
                      return (
                        <MenuItem key={item} value={item} style={{ color: "#000", width: "100%" }}>{formatAMPMTime(item)}</MenuItem>
                      )
                    })}
                    {/* Add more time slots as needed */}
                  </Select>
                </FormControl> : null}

                <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  <Typography style={{ fontSize: '12px' }}>Date</Typography>
                  <TextField onChange={handleDateChange} type="date" InputLabelProps={{ shrink: true }} inputProps={{ min: today }} />
                </FormControl>
              </div>

              {/* Section 2: Contact Info */}
              <div style={{ marginBottom: '15px' }}>
                <Typography variant="h6" style={{ fontSize: '14px', marginBottom: '5px' }}>
                  Fill Contact Info
                </Typography>

                <FormControl className={classes.formControl}>
                  <Typography style={{ fontSize: '12px' }}>Phone Number</Typography>
                  <TextField onChange={(e) => setManualContactInfo(e.target.value)} type="tel" />
                </FormControl>
              </div>

              {/* Section 3: Duration and Slot Time */}
              {selectedCourt ? <div>
                <FormControl component="fieldset" style={{ marginBottom: '15px' }}>
                  <Typography variant="h6" style={{ fontSize: '14px', marginBottom: '5px' }}>
                    Duration
                  </Typography>
                  <RadioGroup row onChange={handleRadioChange}>
                    {durations?.map((item) => {
                      return (
                        <FormControlLabel key={item.interval} value={item.interval} control={<Radio />} label={item.interval} />
                      )
                    })}

                  </RadioGroup>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Typography variant="h6" style={{ fontSize: '14px', marginBottom: '5px' }}>
                    Slot Time
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '12px' }}>{selectedTime} - {addIntervalToTime(selectedTime, selectedDuration)}</Typography>
                  {/* Display the selected slot time */}
                </FormControl>
              </div> : null}
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setSelectedVenue("")
              setSelectedCourt("")
              setManualBooking(false)
            }} color="secondary">
              Close
            </Button>
            <SoftButton onClick={() => {
              createManualBooking()

            }} color="primary">
              Create Booking
            </SoftButton>
          </DialogActions>

        </Dialog>

        <Dialog open={manualBlockBooking}>
          <DialogTitle>Block Slot</DialogTitle>
          <DialogContent>
            <Container className={classes.container}>
              {/* Section 1: Venue, Court, Date, and Time */}
              <div style={{ marginBottom: '15px' }}>
                <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  <Typography style={{ fontSize: '12px' }}>Venue</Typography>
                  <Select IconComponent={() => <ArrowDropDown />} onChange={(e) => {
                    setSelectedVenue(e.target.value)
                    const filteredVenueCourts = courts.filter(court => court.venue === e.target.value);
                    setVenueCourts(filteredVenueCourts)

                  }}>
                    {venues.map((item) => {
                      return (
                        <MenuItem key={item._id} value={item._id} style={{ color: "#000", width: "100%" }}>{item.name}</MenuItem>
                      )
                    })}

                    {/* Add more venues as needed */}
                  </Select>
                </FormControl>

                {selectedVenue ? <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  <Typography style={{ fontSize: '12px' }}>Court</Typography>
                  <Select IconComponent={() => <ArrowDropDown />} onChange={(e) => {
                    setSelectedCourt(e.target.value)
                    const selectedCourtItem = courts.find(court => court._id === e.target.value);
                    const generatedTimings = generateTimings(selectedCourtItem.pricing.dateTime.startTime, selectedCourtItem.pricing.dateTime.endTime)
                    setTimings(generatedTimings)
                    setDurations(selectedCourtItem.pricing.pricing)
                  }}>
                    {venueCourts.map((item) => {
                      if (item.venue === selectedVenue) {
                        return (
                          <MenuItem key={item._id} value={item._id} style={{ color: "#000", width: "100%" }}>{item.title}</MenuItem>
                        )
                      }
                    })}
                    {/* Add more courts as needed */}
                  </Select>
                </FormControl> : null}


                {timings ? <FormControl className={classes.formControl}>
                  <Typography style={{ fontSize: '12px' }}>Time</Typography>
                  <Select IconComponent={() => <ArrowDropDown />} defaultValue={timings[0]} onChange={(e) => {
                    setSelectedTime(e.target.value)
                  }}>
                    {timings.map((item) => {
                      return (
                        <MenuItem key={item} value={item} style={{ color: "#000", width: "100%" }}>{formatAMPMTime(item)}</MenuItem>
                      )
                    })}
                    {/* Add more time slots as needed */}
                  </Select>
                </FormControl> : null}

                <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  <Typography style={{ fontSize: '12px' }}>Date</Typography>
                  <TextField onChange={handleDateChange} type="date" InputLabelProps={{ shrink: true }} inputProps={{ min: today }} />
                </FormControl>
              </div>

              {/* Section 2: Contact Info */}
              <div style={{ marginBottom: '15px' }}>
                <Typography variant="h6" style={{ fontSize: '14px', marginBottom: '5px' }}>
                  Information
                </Typography>

                <FormControl className={classes.formControl}>
                  <Typography style={{ fontSize: '12px' }}>Blocking Reason</Typography>
                  <TextField onChange={(e) => setBlockTimeInfo(e.target.value)} type="text" />
                </FormControl>
              </div>

              {/* Section 3: Duration and Slot Time */}
              {selectedCourt ? <div>
                <FormControl component="fieldset" style={{ marginBottom: '15px' }}>
                  <Typography variant="h6" style={{ fontSize: '14px', marginBottom: '5px' }}>
                    Duration
                  </Typography>
                  <RadioGroup row onChange={handleRadioChange}>
                    {durations?.map((item) => {
                      return (
                        <FormControlLabel key={item.interval} value={item.interval} control={<Radio />} label={item.interval} />
                      )
                    })}

                  </RadioGroup>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Typography variant="h6" style={{ fontSize: '14px', marginBottom: '5px' }}>
                    Slot Time
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '12px' }}>{selectedTime} - {addIntervalToTime(selectedTime, selectedDuration)}</Typography>
                  {/* Display the selected slot time */}
                </FormControl>
              </div> : null}
            </Container>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={() => {
              setSelectedVenue("")
              setSelectedCourt("")
              setManualBlockBooking(false)
            }} color="secondary">
              Close
            </SoftButton>
            <SoftButton onClick={() => {
              createBlockBooking()

            }} color="primary">
              Block
            </SoftButton>
          </DialogActions>

        </Dialog>
        <Dialog open={manualBlockBookingInfoModal}>
          <DialogTitle>Block Slot Info</DialogTitle>
          <DialogContent>
            <Typography variant="h4" style={{ fontSize: '14px', marginBottom: '5px' }}>
              Reason
            </Typography>
            <Typography variant="p" style={{ fontSize: '14px', marginBottom: '5px' }}>
              {bookingDetails?.blockTimeInfo}
            </Typography>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={() => setManualBlockBookingInfoModal(false)} color="light">
              Close
            </SoftButton>
            <SoftButton onClick={() => {
              deleteBooking(bookingDetails._id)
              setManualBlockBookingInfoModal(false)
            }} color="error">
              Delete
            </SoftButton>
          </DialogActions>
        </Dialog>
        <Dialog open={manualBookingInfoModal}>
          <DialogTitle>Manual Booking Info</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Typography variant="body2">
                  <strong>Duration:</strong> {bookingDetails?.duration}
                </Typography>
                <Typography variant="body2">
                  <strong>Status:</strong> {bookingDetails?.status}
                </Typography>
                <Typography variant="body2">
                  <strong>Date:</strong> {bookingDetails?.date}
                </Typography>
                <Typography variant="body2">
                  <strong>Time:</strong> {bookingDetails ? formatAMPMTime(bookingDetails?.dateTimeInfo.timeFrom) : ""} - {bookingDetails ? formatAMPMTime(bookingDetails?.dateTimeInfo.timeTo) : ""}
                </Typography>
                <Typography variant="body2">
                  <strong>Payment Amount:</strong> {bookingDetails?.paymentAmount} SAR
                </Typography>
                <Typography variant="body2">
                  <strong>Contact Number:</strong> {bookingDetails?.manualContactInfo}
                </Typography>
                <Typography variant="body2">
                  <strong>Created At:</strong> {formatReadableDate(bookingDetails?.createdAt)}
                </Typography>
                {bookingDetails?.invoice_url ? <SoftButton onClick={() => { window.open(bookingDetails?.invoice_url, '_blank'); }} color="light">
                  Download Invoice
                </SoftButton> : null}
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={() => setManualBookingInfoModal(false)} color="light">
              Close
            </SoftButton>
            <SoftButton onClick={() => {
              deleteBooking(bookingDetails._id)
              setManualBookingInfoModal(false)
            }} color="error">
              Delete
            </SoftButton>
          </DialogActions>
        </Dialog>
        <Dialog open={bookingInfoModal}>
          <DialogTitle>Booking Info</DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Typography variant="body2">
                  <strong>Duration:</strong> {bookingDetails?.duration}
                </Typography>
                <Typography variant="body2">
                  <strong>Status:</strong> {bookingDetails?.status}
                </Typography>
                <Typography variant="body2">
                  <strong>Date:</strong> {bookingDetails?.date}
                </Typography>
                <Typography variant="body2">
                  <strong>Time:</strong> {bookingDetails ? formatAMPMTime(bookingDetails?.dateTimeInfo.timeFrom) : ""} - {bookingDetails ? formatAMPMTime(bookingDetails?.dateTimeInfo.timeTo) : ""}
                </Typography>
                <Typography variant="body2">
                  <strong>Payment Amount:</strong> {bookingDetails?.paymentAmount} SAR
                </Typography>
                <Typography variant="body2">
                  <strong>Player Name:</strong> {customerDetails?.first_name} {customerDetails?.last_name}
                </Typography>
                <Typography variant="body2">
                  <strong>Player Email:</strong> {customerDetails?.email}
                </Typography>
                <Typography variant="body2">
                  <strong>Contact Number:</strong> {customerDetails?.phone}
                </Typography>
                <Typography variant="body2">
                  <strong>Created At:</strong> {formatReadableDate(bookingDetails?.createdAt)}
                </Typography>
                {bookingDetails?.invoice_url ? <SoftButton onClick={() => { window.open(bookingDetails?.invoice_url, '_blank'); }} color="light">
                  Download Invoice
                </SoftButton> : null}
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <SoftButton onClick={() => setBookingInfoModal(false)} color="light">
              Close
            </SoftButton>
          </DialogActions>
        </Dialog>
        {viewType === "calendar" ? <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Grid item xs={6} md={6}>
                <SoftTypography variant="h6">Bookings Data</SoftTypography>
                <FormControl className={classes.formControl} style={{ marginRight: '5px' }}>
                  {/* <Typography style={{ fontSize: '12px' }}>Date</Typography> */}
                  <TextField onChange={handleSelectDateChange} type="date" InputLabelProps={{ shrink: true }} />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                <SoftButton style={{ marginRight: 10 }} onClick={() => setManualBlockBooking(true)} variant="gradient" color="dark">
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;Block Slot
                </SoftButton>
                <SoftButton onClick={() => setManualBooking(true)} variant="gradient" color="dark">
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;Create Booking
                </SoftButton>
              </Grid>
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {/* <div style={{width:"100%",overflowX:"scroll"}}> 
              <BookingCustomCalendar courts={courts} times={times}/>
              </div> */}
              {tablebookings ? <div style={{ marginLeft: 20, marginRight: 20 }}>
                <StyleWrapper>
                  {/* <Calendar plugins={[ResourceTimeGrid]} {...options} /> */}
                  <FullCalendar
                    ref={fullCalendarRef}
                    plugins={[resourceTimeGridPlugin]}
                    initialView="resourceTimeGridDay"
                    events={calEvents}
                    timeZone="local"
                    eventContent={eventContent}
                    eventDidMount={eventDidMount}
                    dayHeaderContent={dayHeaderContent}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    // resourceHeaderContent={resourceHeaderContent}
                    resources={resources}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'resourceTimeGridWeek,resourceTimeGridDay', // Add other views as needed
                    }}
                    eventClick={handleEventClick}
                    select={(data) => { console.log(data) }}

                  />
                </StyleWrapper>
              </div> : null}


            </SoftBox>
          </Card>
        </SoftBox> : null}
        {viewType === "list" ? <SoftBox>
          <Card>
            <SoftBox>
              <Table columns={columns} rows={bookings} />
            </SoftBox>
          </Card>
        </SoftBox> : null}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Bookings;
