/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import partnersTableData from "layouts/partners/data/partnersTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Grid, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
// import partnersTableData from "./data/partnersTableData";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import courtena from "api/courtena";

function PaymentSuccess() {
  const { columns, rows } = partnersTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [paymentInfo,setPaymentInfo] = useState({id:"",status:"",message:"",invoice_id:""})
  let navigate = useNavigate();
  let location = useLocation();

  const updateState = async(pId,pStatus,pMessage,pInvoiceId) => {
    var partnerInfoString = localStorage.getItem("partner")
    var partnerInfo = JSON.parse(partnerInfoString)
    console.log(partnerInfo)
    setPaymentInfo({id:pId,status:pStatus,message:pMessage,invoice_id:pInvoiceId})
    const data = {id:pId,status:pStatus,message:pMessage,invoice_id:pInvoiceId}
    if(pStatus === "paid"){
      await courtena.put("/partner/subscription/"+partnerInfo._id+"/update/",{...data},{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*',
          'Authorization': partnerInfo.token
      }
      }).then((res) => {
        console.log(res.data)
        if(res.data.success){
          const myObject = JSON.parse(localStorage.getItem('partner'));
          myObject.isSubscribed = res.data.result.partner.isSubscribed;
          localStorage.setItem('partner', JSON.stringify(myObject));

        }
            
      }).catch(err => {
            console.log(err)
      })
    }
    
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    updateState(queryParams.get('id'),queryParams.get('status'),queryParams.get('message'),queryParams.get('invoice_id'))
  },[])
  return (
    <DashboardLayout>
      {/* <DashboardNavbar light={true} /> */}
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card> 
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <Grid item xs={6} md={6}>
                    <SoftTypography variant="h6">Subscription Payment Success</SoftTypography>
                </Grid>
              
            </SoftBox>

            <SoftBox
            p={3}
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {/* Display paymentInfo data here */}
              <SoftTypography variant="body1" fontSize="1rem" mb={1}>
                <strong>ID:</strong> {paymentInfo.id}
              </SoftTypography>
              <SoftTypography variant="body1" fontSize="1rem" mb={1}>
                <strong>Status:</strong> {paymentInfo.status}
              </SoftTypography>
              <SoftTypography variant="body1" fontSize="1rem" mb={1}>
                <strong>Message:</strong> {paymentInfo.message}
              </SoftTypography>
              <SoftTypography variant="body1" fontSize="1rem">
                <strong>Invoice ID:</strong> {paymentInfo.invoice_id}
              </SoftTypography>
            </SoftBox>
          </Card>
        </SoftBox>
        
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PaymentSuccess;
