/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { useNavigate,useLocation } from "react-router-dom";
import SoftAlert from "components/SoftAlert";
import courtena from "api/courtena";
import { Backdrop, CircularProgress } from "@mui/material";
function VerifyAccount() {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error,setError] = useState(false)
  const [success,setSuccess] = useState(false)
  const [errorMessage,setErrorMessage] = useState("")
  const [successMessage,setSuccessMessage] = useState("")
  const [backdrop,setBackdrop] = useState(false)
  const handleSetAgremment = () => setAgremment(!agreement);
  let navigate = useNavigate();
  let location = useLocation();

  const verifyAccount = async () => {
    setBackdrop(true)
    // let data
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('token');
        const data = {vt:paramValue}
        await courtena.post("/auth/verify-account-partner",{...data},{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*'
          }
          }).then((response) => {
            setBackdrop(false)
            if(response.data.success){
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => {
            setBackdrop(false)
            console.log(err.message)
          });
    


  }
  useEffect(() => {
    verifyAccount()
  },[])
  return (
    <BasicLayout
      title=""
      description=""
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          {error?<SoftTypography variant="h5" fontWeight="medium">
            {errorMessage}
          </SoftTypography>:null}
          {success?<SoftTypography variant="h5" fontWeight="medium">
            {successMessage}
          </SoftTypography>:null}
        </SoftBox>

        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
          {error ? <SoftAlert color="error" dismissible onClick={() => setError(false)} > {errorMessage}</SoftAlert> : null}
          {success ? <SoftAlert color="success" dismissible onClick={() => setSuccess(false)} > {successMessage}</SoftAlert> : null}
          </SoftBox>
        </SoftBox>
      </Card>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}>
        <CircularProgress color="inherit" />
        </Backdrop>
    </BasicLayout>
  );
}

export default VerifyAccount;
